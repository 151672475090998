<template>
	<span
		class="tn-badge"
		:class="[size, category, { compact }, { responsive }]"
	>
		<!-- @slot The slot for the text (or icon) that goes within the badge. -->
		<slot></slot>
	</span>
</template>

<script>
import { defineComponent } from "vue";

import sizes from "./definitions/sizes";
import categories from "./definitions/categories";

/**
 * Badges are used to steer the users attention towards specific offers, option, benefits or info.
 * Content within the badge is usually text or icon, and the badge supports a range of colors.
 * @displayName TnBadge
 */
export default defineComponent({
	name: "TnBadge",

	props: {
		/**
		 * The category type for the badge
		 * @values news, offer, recommended
		 */
		category: {
			type: String,
			default: "news",
			validator: function (value) {
				return categories.includes(value.toLowerCase());
			},
		},

		/**
		 * The size of the badge
		 * @values xs, s, m, l
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase()) || "m";
			},
		},
		/**
		 * The padding type
		 * @values default, compact
		 */
		compact: {
			type: Boolean,
			default: false,
		},
		/**
		 * Responsive will shrink font-size one size down on mobile
		 */
		responsive: {
			type: Boolean,
			default: false,
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/typography/scss/placeholders";
@use "@/assets/scss/variables" as variables;
@use "@/assets/typography/scss/responsive" as responsive;

.tn-badge {
	display: inline-flex;
	position: relative;
	vertical-align: top;
	color: variables.$color-primary-dark;
	user-select: none; // Prevents user from accidentally selecting badge when highlighting
	padding: 6px 12px;

	* {
		vertical-align: middle;
	}

	&.compact {
		padding: 4px 8px;
	}

	&.xs {
		@extend %font-text-bold-2xs;

		&.responsive {
			@include responsive.font-text-bold-2xs;
		}
	}

	&.s {
		@extend %font-text-bold-xs;

		&.responsive {
			@include responsive.font-text-bold-xs;
		}
	}

	&.m {
		@extend %font-text-bold-s;

		&.responsive {
			@include responsive.font-text-bold-s;
		}
	}

	&.l {
		@extend %font-text-bold-m;

		&.responsive {
			@include responsive.font-text-bold-m;
		}
	}

	&.news {
		color: variables.$color-primary-dark;
		background-color: variables.$color-primary-light;
	}

	&.offer {
		color: variables.$color-primary-superlight;
		background-color: variables.$color-primary-mid;
	}

	&.recommended {
		color: variables.$color-primary-dark;
		background-color: variables.$color-primary-light;
	}
}
</style>
